import React from 'react';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { TranslationRO } from '@escapenavigator/types/dist/translation/translation.ro';

import { RefetchButton } from './refetch-button';
import { TranslationRow } from './translation-row';

export const columns = (access: boolean): TableColumns<TranslationRO> => [
    {
        header: 'Context',
        accessor: 'row',
        width: '400px',
        row: {
            image: 'photo',
            title: ({ row }) => `${row.namespace.title}, ${row.row}`,
            subtitle: ({ row }) => row.context,
        },
    },
    {
        header: 'Translations',
        accessor: 'translations',
        row: {
            title: ({ row, refetch }) => <TranslationRow translation={ row } cb={ refetch } />,
        },
    },
    {
        header: '',
        accessor: 'id',
        row: {
            title: ({ row, refetch }) => access && <RefetchButton translation={ row } cb={ refetch } />,
        },
    },
];
