import React, { useContext } from 'react';
import { DotsM } from '@alphakits/icons';
import {
    Button, Flex, ModalContext, showError, showSuccess, Table,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { Picker } from 'src/components/picker';
import { FiltersTransactionModal } from 'src/modals/filters/translation';
import { TranslationModal } from 'src/modals/translation';
import { TranslationNamespaceModal } from 'src/modals/translation-namespace';
import { TranslationQuizModal } from 'src/modals/translation-quiz';
import { TranslationUpdateModal } from 'src/modals/translation-update';
import { TranslationUpdateNamespaceModal } from 'src/modals/translation-update-namespace';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const TranslationsPage: React.FC<DefaultPageProps> = () => {
    const { translationsApi } = useApi();

    const { openModal } = useContext(ModalContext);
    const {
        current: { id, email },
    } = useCurrentUser();

    const { generateJsonLoading, generateJsonFetch } = useApiMethod({
        api: translationsApi.generateJson,
        successCallback: () => showSuccess('Geneated'),
        errorCallback: ({ message }) => showError(message),
    });

    const access = id === 999999999 || email === 'fedorru1@gmail.com';

    const col = columns(access);

    const createTranslation = openModal(TranslationModal);

    const createNamespace = openModal(TranslationNamespaceModal);

    const updateNamespace = openModal(TranslationUpdateNamespaceModal);

    const openFilters = openModal(FiltersTransactionModal);

    return (
        <Table.TableComponent
            tagsBuilder={ mapper() }
            filtersButtonLabel="Filters"
            onFiltersClick={ ({ submitCallback }) => openFilters({ submitCallback }) }
            rightHeaderAddons={ (
                <Flex gap="xs" justify="end">
                    <Button
                        size="xs"
                        view="primary"
                        loading={ generateJsonLoading }
                        onClick={ () =>
                            openModal(
                                TranslationQuizModal,
                                'm',
                            )({
                                cb: () => {},
                            }) }
                    >
                        Start translate
                    </Button>
                    { access && (
                        <React.Fragment>
                            <Button
                                size="xs"
                                view="outlined"
                                loading={ generateJsonLoading }
                                onClick={ () => generateJsonFetch(undefined) }
                            >
                                Generate JSON
                            </Button>
                        </React.Fragment>
                    ) }
                    { id === 999999999 && (
                        <React.Fragment>
                            <Picker
                                position="bottom-end"
                                view="outlined"
                                content={ DotsM }
                                actions={ {
                                    'Create namespace': () => createNamespace(),
                                    'Regenerate namespace': () => updateNamespace(),
                                } }
                            />
                            <Button size="xs" view="outlined" onClick={ () => createTranslation() }>
                                Create translation
                            </Button>
                        </React.Fragment>
                    ) }
                </Flex>
            ) }
            onRowClick={
                id === 999999999
                    ? (row, cb) => openModal(TranslationUpdateModal)({ translation: row, cb })
                    : undefined
            }
            columns={ col }
            searchPlaceholder="Find by translation"
            language="en"
            height="fill"
            promise={ translationsApi.query }
        />
    );
};
